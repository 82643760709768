// Based on Pulse 4.3.1 from Bootswatch
// https://bootswatch.com/pulse/

// Adapted to match the Mygon Wiget Theme

// Variables ===================================================================

$border-radius-base: 0.5rem !default;


// Buttons =====================================================================

.btn {
  border-radius: $border-radius-base;

  &:focus,
  &:active,
  &:active:focus,
  &.active:focus {
    outline: none;
  }

  &-secondary {
    background-color: $white;
    border-color: #ccc;
    color: $gray-900;

    &:hover {
      background-color: $gray-300;
      border-color: $gray-500;
      color: $gray-900;
    }

    &.disabled {
      background-color: $white;
      border-color: lighten(#ccc, 5%);
      color: lighten($gray-900, 5%);
    }
  }

  &-warning {
    color: $white;
  }

  &-primary:focus {
    box-shadow: 0 0 5px lighten($primary, 10%);
  }

  &-secondary:focus {
    box-shadow: 0 0 5px $gray-400;
  }

  &-success:focus {
    box-shadow: 0 0 5px lighten($success, 10%);
  }

  &-info:focus {
    box-shadow: 0 0 5px lighten($info, 10%);
  }

  &-warning:focus {
    box-shadow: 0 0 5px lighten($warning, 10%);
  }

  &-danger:focus {
    box-shadow: 0 0 5px lighten($danger, 10%);
  }

  &.disabled:focus {
    box-shadow: none;
  }
}

// Typography ==================================================================

// Tables ======================================================================

.table .thead-dark th {
  background-color: $secondary;
  border-color: $table-border-color;
}

// Forms =======================================================================

.form-control:focus {
  box-shadow: 0 0 5px rgba(100, 65, 164, 0.4);
}

textarea.form-control {
  border-radius: $border-radius-base;
}

.form-control {
  border-color: $gray-500;
}

.form-group {
  margin-bottom: 1.25rem;
}

// Navs ========================================================================

.nav-tabs {
  .nav-link,
  .nav-link.active {
    border-width: 0 0 3px 0;
  }

  .nav-link:hover,
  .nav-link.active,
  .nav-link.active:hover,
  .nav-link.active:focus {
    border-bottom: 3px solid $primary;
  }

  .nav-item + .nav-item {
    margin-left: 0;
  }
}

.breadcrumb {
  &-item.active {
    color: $gray-700;
  }
}

// Indicators ==================================================================

.badge {
  border-radius: 5px;

  &-secondary,
  &-warning {
    color: $white;
  }
}

// Progress bars ===============================================================

.progress {
  height: 8px;
}

// Containers ==================================================================

.list-group {
  &-item {
    color: rgba(255, 255, 255, 0.8);

    &.active,
    &:hover,
    &:focus {
      color: #fff;
    }

    &.active {
      font-weight: bold;

      &:hover {
        background-color: $list-group-hover-bg;
      }
    }

    &.disabled:hover {
      color: $list-group-disabled-color;
    }
  }
}

// ############ Start Mygon-specific elements declarations ############


@media (pointer: fine) {
  // Mouse-based devices (desktops, laptops)
  html {
    font-size: 100%; // 16 px
    min-width: 310px;
  }
}

.next-step-btn-wrapper {
  border-top: 1px solid $gray-500;

  .next-step-btn-container {
    @include make-container();
    width: 100%;
    max-width: map-get($container-max-widths, sm);
    padding: 0.6rem 0.6rem calc(0.6rem + env(safe-area-inset-bottom));

    @include media-breakpoint-up(sm) {
      padding: 0.8rem;
    }

    .btn-next-step {
      border-radius: $border-radius-base;
      @include button-variant($primary, $primary);
      font-size: 1.4rem;
      padding: 0.3em 0.75rem;
      text-transform: uppercase;

      @include media-breakpoint-up(sm) {
        font-size: 1.3rem;
      }
    }
  }
}

.btn-inc-dec {
  @include button-variant($white, $gray-500);
  padding: 0.2rem 0.2rem;
  border-radius: 0;
}

.btn-smart-pill {
  @include button-variant($smart, $smart);
  padding: 0.5em;
  border-radius: 9999px;
  color: $white;
  font-weight: bold;
}

.btn-outlined {
  @include button-variant($white, $gray-500);
  padding: 0.5em;
  border-radius: $border-radius-base;
  border-width: 2px;
  color: $gray-600;
}

.btn.social-login {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.8rem 0.3rem 0.3rem;
  margin: 0.3rem auto;
  border-radius: 0.3rem;
  min-width: 280px;
  max-width: 280px;
  font-size: 1rem;
  font-weight: 500;

  &.btn-facebook {
    @include button-variant(#1877f2, #1877f2);
  }

  &.btn-google {
    @include button-variant($white, #dadce0);
  }

  &.g_id_signin {
    padding: 0;

    &:empty {
      margin: 0 auto;
    }
  }

  &.btn-apple {
    @include button-variant($black, $black);
  }

  .icon-wrapper {
    margin-right: 0.6rem;
    margin-left: 0.4rem;
  }

  span.label {
    margin: 0 auto;
  }
}

.badge-smart {
  color: $white;
}

.modal-content {
  border: 4px solid $primary;
  border-radius: $border-radius-base;
  background-clip: unset;

  .modal-header,
  .modal-body,
  .modal-footer {
    border: none;
  }

  .modal-body {
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
    white-space: pre-line;
  }

  .modal-footer {
    justify-content: center;
  }
}

input.rounded-underline,
select.rounded-underline {
  border-radius: $border-radius-base;
  border-top: none;
  border-left: none;
  border-right: none;

  &:focus {
    box-shadow: none;
  }
}

.lightgray-container {
  background-color: $gray-300;
  border-bottom: 1px solid $gray-500;
  padding: 0.5rem 0;
  width: 100%;
}
.gray-container {
  background-color: $gray-400;
  border-bottom: 1px solid $gray-500;
  padding: 0.7rem 0;
  width: 100%;
}

.large-text {
  font-size: 1.2rem;
  color: $gray-600;
}

.medium-text {
  font-size: 1rem;
  color: $gray-600;
}

.small-text {
  font-size: 0.8rem;
  color: $gray-600;
}

.merchant-notes {
  font-size: 1rem;
  white-space: pre-line;
}
